@import "./functions";

// colors
$white: #ffffff;
$black: #000000;
$red: #a20000;

$grey: #dadada;
$dark-grey: #3d3d3d; // main body text
$middle-grey: #707070; // secondary body text
$light-grey: #b0b0b0;
$very-light-grey: #dfdfdf;

$off-white: #f6f6f6;

$background-color-selected: rgba(0, 129, 138, 0.1);
$background-color-clicked: rgba(0, 129, 138, 0.05);

$bridge-dark: #00818a;
$bridge-dark20: rgba(0, 129, 138, 0.2);
$bridge-dark10: rgba(0, 129, 138, 0.1);
$bridge-dark05: rgba(0, 129, 138, 0.05);

// font-size

$font-size-small: rc(14);
$font-size-normal: rc(16);
$font-size-big: rc(20);

// padding

$padding-small: rc(12);
$padding-normal: rc(20);
$padding-big: rc(40);

// margin
$margin-small: rc(12);
$margin-normal: rc(20);
$margin-big: rc(40);

// icons
$icon-small: rc(24);
$icon-normal: rc(32);
$icon-big: rc(40);
