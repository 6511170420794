// app-base store styles which are added once in whole application
// Reset
@import "./app-base/reset";
@import "./core/variables";
@import "./core/functions";
@import "./core/typography";
@import "./core/bfc-overwrite";

::-webkit-scrollbar {
  width: 1.1rem;
}
::-webkit-scrollbar-thumb {
  background-color: $very-light-grey;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
}

::placeholder {
  opacity: 1;
  color: $light-grey;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
}

:-ms-input-placeholder {
  color: $light-grey;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
}

::-ms-input-placeholder {
  color: $light-grey;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
}

html {
  font-size: 16px;
}

body {
  font-size: rc(12);
  font-family: var(--font-family);
  font-weight: normal;
  line-height: rc(20);
  color: $dark-grey;
  background: $off-white;
  overflow-x: hidden;
  position: relative;
  margin: 0;
  min-height: 99vh;
}

* {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
